// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".qr-container{display:flex;flex-flow:column;align-items:center}.qr-container__image{border-radius:3%;margin:30px 0px}.qr-container__title{font-size:1.125rem;color:#262834;width:100%;margin:0;text-align:center}.otp-container{display:flex;justify-content:space-between;margin:20px 0;width:100%}.otp-container__digit{width:40px;height:40px;text-align:center}.otp-container__title{font-size:0.875rem;color:rgba(71,75,97,.6);width:100%;text-align:justify}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
