// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".page{display:flex;flex-direction:column;width:100%;height:100%;overflow:auto}.page__header{border-bottom:1px solid #d9d9d9;padding:30px}@media(min-width: 769px){.page__header{margin:0 46px;padding:46px 46px 35px 46px}}.page__content{display:flex;justify-content:center;align-content:center;margin:auto 0}.page__footer{text-align:center;margin:50px 46px}.page__title{font-weight:500;font-size:1.875rem;letter-spacing:-0.015em;color:#e8751c}@media(min-width: 769px){.page__title{font-size:2.67rem}}.page__footer-text{font-size:0.7rem;color:#474b61;line-height:13px}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
