// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".search-cnt{width:100%;border-radius:100px;overflow:hidden;background-color:#fff;height:calc(40px - 6px);margin:3px 0;padding:0 16px;display:flex;align-items:center;justify-content:center;gap:8px;box-sizing:border-box}.search-cnt--with-border{border:1px solid #4d4d4d}.search-cnt__input{height:100%;width:100%;border:0;padding:0;color:#262834;background:rgba(0,0,0,0);outline:none}.search-cnt__input::placeholder{color:#8c8c8c}.search-cnt__icon{cursor:pointer;background:rgba(0,0,0,0);outline:none;border:0;padding:6px}.search-cnt__icon[disabled]{cursor:default}.search-cnt__icon span{color:#262834}.search-cnt__search{font-size:18px}.search-cnt__close{font-size:11px}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
