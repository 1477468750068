// Generated automatically by nearley, version 2.20.1
// http://github.com/Hardmath123/nearley
(function () {
function id(x) { return x[0]; }

/* eslint-disable */

const moo = require("moo");

/**
 * Flatten array of arrays to single array.
 * If object will not be defined or not an array, default value will be returned.
 *
 * @param {Array.<*>} array - Array to be flatten.
 * @param {*} defaultValue - Default value.
 *
 * @returns {Array.<*> | *} Flatten array or default value.
 */
const flatten = (array, defaultValue = null) => {
  if (!array || !Array.isArray(array)) {
    return defaultValue;
  }

  return array.reduce((acc, current) => acc.concat(current), []);
};

/**
  *Extracting value from objects.
  *Simple version of json.ne injected through this functions.
*/
function extractPair(kv, output) {
    if(kv[0]) { output[kv[0]] = kv[1]; }
}

function extractObject(d) {
    let output = {};

    extractPair(d[1], output);

    for (let i in d[2]) {
        extractPair(d[2][i][1], output);
    }

    return output;
}

/**
 * Checks if variable is empty.
 *
 * @param {*} v - Variable to be checked.
 * @param {*} defaultValue - Default value.
 *
 * @returns {*} When empty - default value will be used.
 */
const variableOrDefault = (v, defaultValue = null) => ((v && v.length > 0) ? v : defaultValue);

const sigInline = { "#SIG": "#SIG" };

const lexer = moo.compile({
  openIfBlock: { match: /{{\s*#IF\((?:.+?)\)\s*}}/, value: s => {
      const expression = s.slice(s.indexOf('(') + 1, s.lastIndexOf(')'))
      return { operator: "if", expression }
    }
  },
  otherIfBlock: [ "#ELSE", "/IF" ],
  openWrapBlock: { match: /{{\s*#WRAP\s*}}/, value: () => ({ operator: "wrap" }) },
  otherWrapBlock: ["/WRAP"],
  openEachBlock: { match: /{{\s*#EACH\((?:.+?)\)\s*}}/, value: s => {
      const expression = s.slice(s.indexOf('(') + 1, s.lastIndexOf(')'))
      return { operator: "each", expression }
    }
  },
  otherEachBlock: [ "/EACH" ],
  imgInline: { match: /{{\s*#IMG\(\s*(?:"(?:.+?)"|(?:SETTING\["(?:.*?)"\]))\s*(?:,\s*{(?:.+?)}\s*)?\)\s*}}/, value: s => {
      const match = s.match(/{{\s*#IMG\(\s*("(.+?)"|(SETTING\["(.*?)"\]))\s*(,\s*{(.+?)}\s*)?\)\s*}}/);
      const value = { type: "inline", operator: "image" };
      const imageOptions = match[6]?.trim().replace(/\s/g, '') || null;
      value.imageOptions = imageOptions;
      const fieldName = match[2]?.trim();
      if(fieldName){
        value.fieldName = fieldName;
      }
      const settingFieldName = match[4]?.trim();
      if(settingFieldName){
        value.settingFieldName = settingFieldName;
      }
      return value;
    }
  },
  sigInline: { match: /{{\s*#SIG\(\s*"(?:.+?)"\s*(?:,\s*{(?:.+?)}\s*)?\)\s*}}/, value: s => {
      const match = s.match(/{{\s*#SIG\(\s*"(.+?)"\s*(,\s*{(.+?)}\s*)?\)\s*}}/);
      const value = { type: "inline", operator: "signature" };
      const imageOptions = match[3]?.trim().replace(/\s/g, '') || null;
      value.imageOptions = imageOptions;
      const fieldName = match[1]?.trim();
      value.fieldName = fieldName;
      return value;
    }
  },
  expressionInline: { match: /{{(?![#\/])(?:[^{}]|{{[^{}]*}})*}}/, value: s => s.slice(2, -2)},
  "{{": "{{",
  "}}": "}}",
  any: [{ match: /[\S\s]*?(?={{)/ }, { match: /[\S\s]+/, lineBreaks: true }],
  number: /(?:[0-9]|[1-9][0-9]+)(?:\.[0-9]+)?(?:[eE][-+]?[0-9]+)?\b/,
	string: /"(?:\\["bfnrt\/\\]|\\u[a-fA-F0-9]{4}|[^"\\])*"/,
	space: {match: /\s+/, lineBreaks: true},
});

var grammar = {
    Lexer: lexer,
    ParserRules: [
    {"name": "template", "symbols": ["fragments", "any"], "postprocess": ([fragments, close]) => ({fragments: flatten(fragments), close: variableOrDefault(close) })},
    {"name": "fragments$ebnf$1", "symbols": []},
    {"name": "fragments$ebnf$1$subexpression$1", "symbols": ["fragment"]},
    {"name": "fragments$ebnf$1", "symbols": ["fragments$ebnf$1", "fragments$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "fragments", "symbols": ["fragments$ebnf$1"], "postprocess": id},
    {"name": "fragment", "symbols": ["any", "expression"], "postprocess": ([before, element]) => ({ before: variableOrDefault(before), ...element })},
    {"name": "expression", "symbols": ["ifBlock"], "postprocess": ([expression]) => expression},
    {"name": "expression", "symbols": ["eachBlock"], "postprocess": ([expression]) => expression},
    {"name": "expression", "symbols": ["wrapBlock"], "postprocess": ([expression]) => expression},
    {"name": "expression", "symbols": [(lexer.has("expressionInline") ? {type: "expressionInline"} : expressionInline)], "postprocess": ([expression]) => ({type: "inline", operator:"expression", expression: expression.value})},
    {"name": "expression", "symbols": [(lexer.has("sigInline") ? {type: "sigInline"} : sigInline)], "postprocess": ([d]) => d.value},
    {"name": "expression", "symbols": [(lexer.has("imgInline") ? {type: "imgInline"} : imgInline)], "postprocess": ([d]) => d.value},
    {"name": "wrapBlock", "symbols": [(lexer.has("openWrapBlock") ? {type: "openWrapBlock"} : openWrapBlock), "template", "closeWrapBlock"], "postprocess":  d => ({
        type: 'block',
        block: d[0].value,
        content: d[1],
        }) },
    {"name": "openWrapBlock", "symbols": [{"literal":"{{"}, {"literal":"#WRAP"}, {"literal":"}}"}], "postprocess": d => ({ operator: 'wrap' })},
    {"name": "closeWrapBlock", "symbols": [{"literal":"{{"}, {"literal":"/WRAP"}, {"literal":"}}"}], "postprocess": d => d[1]},
    {"name": "ifBlock", "symbols": [(lexer.has("openIfBlock") ? {type: "openIfBlock"} : openIfBlock), "template", "elseBlock", "template", "closeIfBlock"], "postprocess":  d => ({
          type: 'block',
          block: { ...d[0].value, hasElse: true },
          content: [d[1], d[3]],
        })},
    {"name": "ifBlock", "symbols": [(lexer.has("openIfBlock") ? {type: "openIfBlock"} : openIfBlock), "template", "closeIfBlock"], "postprocess":  d => ({
          type: 'block',
          block: d[0].value,
          content: [d[1]],
        })},
    {"name": "openIfBlock", "symbols": [{"literal":"{{"}, {"literal":"#IF"}, (lexer.has("blockExpression") ? {type: "blockExpression"} : blockExpression), {"literal":"}}"}], "postprocess": d => ({operator: "if", expression: d[2].value })},
    {"name": "closeIfBlock", "symbols": [{"literal":"{{"}, {"literal":"/IF"}, {"literal":"}}"}], "postprocess": d => d[1]},
    {"name": "elseBlock", "symbols": [{"literal":"{{"}, {"literal":"#ELSE"}, {"literal":"}}"}], "postprocess": d => ({operator: "else", expression: '' })},
    {"name": "eachBlock", "symbols": [(lexer.has("openEachBlock") ? {type: "openEachBlock"} : openEachBlock), "template", "closeEachBlock"], "postprocess":  d => ({
          type: 'block',
          block: d[0].value,
          content: d[1],
        }) },
    {"name": "openEachBlock", "symbols": [{"literal":"{{"}, {"literal":"#EACH"}, (lexer.has("blockExpression") ? {type: "blockExpression"} : blockExpression), {"literal":"}}"}], "postprocess": d => ({ operator: 'each', expression: d[2].value })},
    {"name": "closeEachBlock", "symbols": [{"literal":"{{"}, {"literal":"/EACH"}, {"literal":"}}"}]},
    {"name": "any", "symbols": []},
    {"name": "any", "symbols": [(lexer.has("any") ? {type: "any"} : any)], "postprocess": ([any]) => any ? any.value : null},
    {"name": "object", "symbols": [{"literal":"{"}, "_", {"literal":"}"}], "postprocess": function(d) { return {}; }},
    {"name": "object$ebnf$1", "symbols": []},
    {"name": "object$ebnf$1$subexpression$1", "symbols": [{"literal":","}, "pair"]},
    {"name": "object$ebnf$1", "symbols": ["object$ebnf$1", "object$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "object", "symbols": [{"literal":"{"}, "pair", "object$ebnf$1", "_", {"literal":"}"}], "postprocess": extractObject},
    {"name": "number", "symbols": [(lexer.has("number") ? {type: "number"} : number)], "postprocess": function(d) { return parseFloat(d[0].value) }},
    {"name": "string", "symbols": [(lexer.has("string") ? {type: "string"} : string)], "postprocess":  function(d) {
          return d[0].value.slice(1, d[0].value.length - 1);
        } },
    {"name": "pair", "symbols": ["key", "_", {"literal":":"}, "_", "string"], "postprocess": function(d) { return [d[0], d[4]]; }},
    {"name": "key", "symbols": ["string"], "postprocess": id},
    {"name": "_", "symbols": []},
    {"name": "_", "symbols": [(lexer.has("space") ? {type: "space"} : space)], "postprocess": function(d) { return null; }}
]
  , ParserStart: "template"
}
if (typeof module !== 'undefined'&& typeof module.exports !== 'undefined') {
   module.exports = grammar;
} else {
   window.grammar = grammar;
}
})();
