// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".k-window>.k-window-content>.k-pdf-viewer{width:100%;height:100%;font-family:\"Ubuntu\",sans-serif;border-color:#b3b3b3;border-radius:5px}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
