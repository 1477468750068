// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".admin-console.k-window{font-family:\"Ubuntu\",sans-serif;font-size:14px}.admin-console.k-window .k-window-content{padding:16px 16px !important}.admin-console.k-window .k-window-content .k-content{padding:0px;margin:0px}.admin-console.k-window .k-window-content .k-content .k-animation-container{width:100%}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
