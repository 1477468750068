// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".auth-wrapper{display:flex;flex-flow:column;width:100%;background-color:#fff;box-sizing:border-box;justify-content:center;font-family:\"Ubuntu\",sans-serif}.auth-wrapper__back{display:flex;align-items:center;gap:4px;margin-bottom:20px;color:#7f7f7f;text-decoration:none}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
