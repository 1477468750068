// Generated automatically by nearley, version 2.20.1
// http://github.com/Hardmath123/nearley
(function () {
function id(x) { return x[0]; }

const moo = require("moo");

const lexer = moo.compile({
  aeroBizzDotStream: /^.*?aero\.bizz\.stream/,
  aeroBizzStreamDotCom: /^.*?aero\.bizzstream\.com/,
  appDotMetacomPlatformDotCom:  /^.*?app\.[a-zA-Z]*\.?metacomplatform\.com/,
  appDotMetacomPlatformDotNl: /^.*?app\.[a-zA-Z]*\.?metacom\.nl/,
  selectedEquals: 'selected=',
  slashMenuEnd: /\/menu$/,
  slashMenuSlash: /\/menu\//,
  slashMenuQuestionMark: /\/menu\?/,
  setting: /\{\{SETTING\["[^!\}]+"\]\}\}/,
  identifier: /[-_a-zA-Z0-9\{\}]+/,
  mongoFilter: /'[^']+'/,
  questionMark: '?',
  leftParenthesis: '(',
  rightParenthesis: ')',
  equals: '=',
  slash: '/',
  comma: ',',
  ampersand: '&',
  doubleQuotes: '"',
});
var grammar = {
    Lexer: lexer,
    ParserRules: [
    {"name": "url", "symbols": ["host"]},
    {"name": "url", "symbols": ["host", "path"]},
    {"name": "url", "symbols": ["path"]},
    {"name": "host", "symbols": [(lexer.has("aeroBizzDotStream") ? {type: "aeroBizzDotStream"} : aeroBizzDotStream)]},
    {"name": "host", "symbols": [(lexer.has("aeroBizzStreamDotCom") ? {type: "aeroBizzStreamDotCom"} : aeroBizzStreamDotCom)]},
    {"name": "host", "symbols": [(lexer.has("appDotMetacomPlatformDotCom") ? {type: "appDotMetacomPlatformDotCom"} : appDotMetacomPlatformDotCom)]},
    {"name": "host", "symbols": [(lexer.has("appDotMetacomPlatformDotNl") ? {type: "appDotMetacomPlatformDotNl"} : appDotMetacomPlatformDotNl)]},
    {"name": "path", "symbols": [(lexer.has("slash") ? {type: "slash"} : slash), (lexer.has("identifier") ? {type: "identifier"} : identifier)]},
    {"name": "path", "symbols": [(lexer.has("slash") ? {type: "slash"} : slash), (lexer.has("identifier") ? {type: "identifier"} : identifier), (lexer.has("slashMenuEnd") ? {type: "slashMenuEnd"} : slashMenuEnd)]},
    {"name": "path", "symbols": [(lexer.has("slash") ? {type: "slash"} : slash), (lexer.has("identifier") ? {type: "identifier"} : identifier), (lexer.has("slashMenuSlash") ? {type: "slashMenuSlash"} : slashMenuSlash), "blocks"]},
    {"name": "path", "symbols": [(lexer.has("slash") ? {type: "slash"} : slash), (lexer.has("identifier") ? {type: "identifier"} : identifier), (lexer.has("slashMenuSlash") ? {type: "slashMenuSlash"} : slashMenuSlash), "blocks", (lexer.has("questionMark") ? {type: "questionMark"} : questionMark), "params"]},
    {"name": "path", "symbols": [(lexer.has("slash") ? {type: "slash"} : slash), (lexer.has("identifier") ? {type: "identifier"} : identifier), (lexer.has("slashMenuQuestionMark") ? {type: "slashMenuQuestionMark"} : slashMenuQuestionMark), "params"]},
    {"name": "path", "symbols": [(lexer.has("slashMenuEnd") ? {type: "slashMenuEnd"} : slashMenuEnd)]},
    {"name": "path", "symbols": [(lexer.has("slashMenuSlash") ? {type: "slashMenuSlash"} : slashMenuSlash), "blocks"]},
    {"name": "path", "symbols": [(lexer.has("slashMenuSlash") ? {type: "slashMenuSlash"} : slashMenuSlash), "blocks", (lexer.has("questionMark") ? {type: "questionMark"} : questionMark), "params"]},
    {"name": "path", "symbols": [(lexer.has("slashMenuQuestionMark") ? {type: "slashMenuQuestionMark"} : slashMenuQuestionMark), "params"]},
    {"name": "blocks", "symbols": ["block"]},
    {"name": "blocks", "symbols": ["block", (lexer.has("slash") ? {type: "slash"} : slash), "blocks"]},
    {"name": "block", "symbols": ["blockId"]},
    {"name": "block", "symbols": ["blockId", (lexer.has("leftParenthesis") ? {type: "leftParenthesis"} : leftParenthesis), "datasets", (lexer.has("rightParenthesis") ? {type: "rightParenthesis"} : rightParenthesis)]},
    {"name": "blockId", "symbols": [(lexer.has("identifier") ? {type: "identifier"} : identifier)]},
    {"name": "blockId", "symbols": [(lexer.has("setting") ? {type: "setting"} : setting)]},
    {"name": "datasets", "symbols": ["dataset"]},
    {"name": "datasets", "symbols": ["dataset", (lexer.has("comma") ? {type: "comma"} : comma), "datasets"]},
    {"name": "dataset", "symbols": [(lexer.has("leftParenthesis") ? {type: "leftParenthesis"} : leftParenthesis), (lexer.has("identifier") ? {type: "identifier"} : identifier), (lexer.has("comma") ? {type: "comma"} : comma), (lexer.has("mongoFilter") ? {type: "mongoFilter"} : mongoFilter), (lexer.has("rightParenthesis") ? {type: "rightParenthesis"} : rightParenthesis)]},
    {"name": "params", "symbols": ["param"]},
    {"name": "params", "symbols": ["param", (lexer.has("ampersand") ? {type: "ampersand"} : ampersand), "param"]},
    {"name": "param", "symbols": [(lexer.has("selectedEquals") ? {type: "selectedEquals"} : selectedEquals), "selections"]},
    {"name": "selections", "symbols": ["selection"]},
    {"name": "selections", "symbols": ["selection", (lexer.has("comma") ? {type: "comma"} : comma), "selections"]},
    {"name": "selection", "symbols": [(lexer.has("leftParenthesis") ? {type: "leftParenthesis"} : leftParenthesis), (lexer.has("identifier") ? {type: "identifier"} : identifier), (lexer.has("comma") ? {type: "comma"} : comma), (lexer.has("identifier") ? {type: "identifier"} : identifier), (lexer.has("comma") ? {type: "comma"} : comma), (lexer.has("identifier") ? {type: "identifier"} : identifier), (lexer.has("rightParenthesis") ? {type: "rightParenthesis"} : rightParenthesis)]}
]
  , ParserStart: "url"
}
if (typeof module !== 'undefined'&& typeof module.exports !== 'undefined') {
   module.exports = grammar;
} else {
   window.grammar = grammar;
}
})();
