// Generated automatically by nearley, version 2.20.1
// http://github.com/Hardmath123/nearley
(function () {
function id(x) { return x[0]; }


const moo = require('moo')

let lexer = moo.compile({
    space: {match: /\s+/, lineBreaks: true},
    simpleOperator: ['+', '-', '*', '/', '^', '&', '>', '<', '='],
    number: /(?:[0-9]|[1-9][0-9]+)(?:\.[0-9]+)?(?:[eE][-+]?[0-9]+)?\b/,
    string: /"(?:\\["bfnrt\/\\]|\\u[a-fA-F0-9]{4}|[^"\\])*"/,
    stringWithSingleQuotes: /'(?:\\['bfnrt\/\\]|\\u[a-fA-F0-9]{4}|[^'\\])*'/,
    '{': '{',
    '}': '}',
    '[': '[',
    ']': ']',
    ',': ',',
    ':': ':',
    '(': '(',
    ')': ')',
    true: 'true',
    false: 'false',
    null: 'null',
    undefined: 'undefined',
    separator: '.',
    formatOperator: ['FORMAT', 'LABEL', ],
    arrayOutputOperator: ['CONCATENATE', 'UNIQUE'],
    conditionalArrayOperator: ['COUNTIF', 'SUMIF', 'FILTERARRAY'],
    arrayOperator: ['SUM', 'MIN', 'MAX', 'AVG', 'INDEX', 'ZIPTEXTJOIN', 'TEXTJOIN', 'COUNT'],
    setValuesOperator: 'SETVALUES',
    filterOperator: 'FILTER',
    controlOperators: ['SELECTED', 'CHECKED', 'SETSTATE', 'ISOVERLAYLAYOUT'],
    booleanOperators: ['AND', 'OR', 'NOT', 'IF', 'ISBLANK', 'INCLUDES', 'EXISTS'],
    stateReference: ['DS', 'M', 'CTRL', 'LAY', 'OVRLTGT', 'OVRLSRC'],
    documentReference: ['F', 'L', 'SRL', 'SRO', 'O'],
    date: ['NOW', 'TODAY', 'DATE', 'TIME', 'TIMEVALUE'],
    system: ['USER', 'ENV', 'SETTING', 'T', 'DEVICE', 'ENROLLMENT_URL'],
    referenceDocument: ['REF'],
})



function extractPair(kv, output) {
    if(kv[0]) { output[kv[0]] = kv[1]; }
}

function extractObject(d) {
    let output = {};

    extractPair(d[1], output);

    for (let i in d[2]) {
        extractPair(d[2][i][1], output);
    }

    return output;
}

function extractArray(d) {
    let output = [d[2]];

    for (let i in d[3]) {
        output.push(d[3][i][3]);
    }

    return output;
}



const pointerExtraContextKeys = ['layoutName', 'datasetName', 'menuName', 'datasetIndex', 'lookInOverlayLayout', 'lookInSourceLayout'];

/**
 * Adds extra context from "array ast" to the "condition ast".
 * For example, the array might be from  a different dataset,
 * in which case we want the condition to also look in that dataset.
 *
 * @param {object} arrayAst - Array ast.
 * @param {object} conditionAst - Condition ast.
 * @returns {object} - New condition ast.
 * @private
 */
function _assignPointerContext(arrayAst, conditionAst) {
  const newConditionAst = conditionAst;
  if (typeof arrayAst === 'object' && arrayAst._bsInternal) {
    pointerExtraContextKeys.forEach(key => {
      if (arrayAst[key]) {
        newConditionAst[key] = arrayAst[key];
      }
    });
  }

  return newConditionAst;
}

/**
 * Computes the condition schema path.
 * Given one of the following array situations:
 *  - ARRAY EXPR: 'L["ingredients"][*].F["_id"]' -> this yields schema path "lines.ingredients.fields._id"
 *  - ARRAY EXPR: 'L["ingredients"][*] -> this yields schema path "line.ingredients".
 *
 * And the following CONDITION EXPR: 'SRL.F["name"]', the result would be:
 *  - "lines.ingredients.fields.name" (correction of 2, so remove the 'fields._id' part)
 *  - "lines.ingredients.fields.name" (correction of 0, does not remove anything).
 *
 *
 * @param {object} arrayAst - Array ast.
 * @param {object} conditionAst - Condition ast.
 * @returns {string} - New condition schema path.
 * @private
 */
function _getConditionAstSchemaPath(arrayAst, conditionAst) {
  const correction = arrayAst.pointerType === 'line' ? 0 : 2;
  const arraySchemaPathParts = arrayAst.schemaPath.split('.');
  const parentSchemaPath = `${arraySchemaPathParts.slice(0, arraySchemaPathParts.length - correction).join('.')}`;
  return `${parentSchemaPath}.${conditionAst.schemaPath}`;
}

/**
 * Adds metadata from the array expression ast (things like datasetName, layoutName etc.)
 * To the condition expression ast.
 *
 * For example: FILTERARRAY(DS["ds1"].L["ingredients"][*].F["_id"], SRL.F["name] = "someName").
 *
 * This would add the "ds1" dataset name to the SRL.F["name"] ast.
 *
 *
 * @param {object} arrayAst - Array ast.
 * @param {object} conditionAst - Condition ast.
 * @returns {object} - New condition ast.
 */
function annotateConditionAST(arrayAst, conditionAst) {
  const arraySchemaPath = arrayAst?.schemaPath;
  if (typeof conditionAst === 'object' && conditionAst._bsInternal) {
    const newConditionAst = { ...conditionAst };
    if (arraySchemaPath && conditionAst.pointerType === 'sourceLineField') {
      _assignPointerContext(arrayAst, newConditionAst);
      newConditionAst.schemaPath = _getConditionAstSchemaPath(arrayAst, newConditionAst);
    }

    Object.keys(newConditionAst).forEach(key => {
      newConditionAst[key] = annotateConditionAST(arrayAst, newConditionAst[key]);
    });
    return newConditionAst;
  } if (conditionAst instanceof Array) {
    return conditionAst.map(value => annotateConditionAST(arrayAst, value));
  }
  return conditionAst;
}

var grammar = {
    Lexer: lexer,
    ParserRules: [
    {"name": "object", "symbols": [{"literal":"{"}, "_", {"literal":"}"}], "postprocess": function(d) { return {}; }},
    {"name": "object$ebnf$1", "symbols": []},
    {"name": "object$ebnf$1$subexpression$1", "symbols": [{"literal":","}, "pair"]},
    {"name": "object$ebnf$1", "symbols": ["object$ebnf$1", "object$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "object", "symbols": [{"literal":"{"}, "pair", "object$ebnf$1", "_", {"literal":"}"}], "postprocess": extractObject},
    {"name": "array", "symbols": [{"literal":"["}, "_", {"literal":"]"}], "postprocess": function(d) { return []; }},
    {"name": "array$ebnf$1", "symbols": []},
    {"name": "array$ebnf$1$subexpression$1", "symbols": ["_", {"literal":","}, "_", "expr"]},
    {"name": "array$ebnf$1", "symbols": ["array$ebnf$1", "array$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "array", "symbols": [{"literal":"["}, "_", "expr", "array$ebnf$1", "_", {"literal":"]"}], "postprocess": extractArray},
    {"name": "value", "symbols": ["object"], "postprocess": id},
    {"name": "value", "symbols": ["array"], "postprocess": id},
    {"name": "value", "symbols": ["number"], "postprocess": id},
    {"name": "value", "symbols": ["string"], "postprocess": id},
    {"name": "value", "symbols": [{"literal":"true"}], "postprocess": function(d) { return true; }},
    {"name": "value", "symbols": [{"literal":"false"}], "postprocess": function(d) { return false; }},
    {"name": "value", "symbols": [{"literal":"null"}], "postprocess": function(d) { return null; }},
    {"name": "value", "symbols": [{"literal":"undefined"}], "postprocess": function(d) { return undefined; }},
    {"name": "number", "symbols": [(lexer.has("number") ? {type: "number"} : number)], "postprocess": function(d) { return parseFloat(d[0].value) }},
    {"name": "number", "symbols": [{"literal":"-"}, (lexer.has("number") ? {type: "number"} : number)], "postprocess": function(d) { return -parseFloat(d[1].value) }},
    {"name": "string", "symbols": [(lexer.has("string") ? {type: "string"} : string)], "postprocess":  function(d) {
          return d[0].value.slice(1, d[0].value.length - 1);
        } },
    {"name": "string", "symbols": [(lexer.has("stringWithSingleQuotes") ? {type: "stringWithSingleQuotes"} : stringWithSingleQuotes)], "postprocess":  function(d) {
          return d[0].value.slice(1, d[0].value.length - 1);
        } },
    {"name": "pair", "symbols": ["key", "_", {"literal":":"}, "_", "expr"], "postprocess": function(d) { return [d[0], d[4]]; }},
    {"name": "key", "symbols": ["string"], "postprocess": id},
    {"name": "_", "symbols": []},
    {"name": "_", "symbols": [(lexer.has("space") ? {type: "space"} : space)], "postprocess": function(d) { return null; }},
    {"name": "systemField", "symbols": ["user"], "postprocess": id},
    {"name": "systemField", "symbols": ["environment"], "postprocess": id},
    {"name": "systemField", "symbols": ["translation"], "postprocess": id},
    {"name": "systemField", "symbols": ["setting"], "postprocess": id},
    {"name": "systemField", "symbols": ["device"], "postprocess": id},
    {"name": "systemField", "symbols": ["enrollmentUrl"], "postprocess": id},
    {"name": "user", "symbols": [{"literal":"USER"}, {"literal":"["}, "expr", {"literal":"]"}], "postprocess":  (d) => {
            return {
                _bsInternal: true,
                operator: "user",
                fieldName: d[2]
            }
        }
         },
    {"name": "environment", "symbols": [{"literal":"ENV"}, {"literal":"["}, "expr", {"literal":"]"}], "postprocess":  (d) => {
            return {
                _bsInternal: true,
                operator: "environment",
                fieldName: d[2]
            }
        }
         },
    {"name": "setting", "symbols": [{"literal":"SETTING"}, {"literal":"["}, "expr", {"literal":"]"}], "postprocess":  (d) => {
            return {
                _bsInternal: true,
                operator: "setting",
                fieldName: d[2]
            }
        }
         },
    {"name": "translation", "symbols": [{"literal":"T"}, {"literal":"["}, "expr", {"literal":"]"}], "postprocess":  (d) => {
            return {
                _bsInternal: true,
                operator: "translation",
                labelToTranslate: d[2]
            }
        }
          },
    {"name": "device$subexpression$1", "symbols": ["string"]},
    {"name": "device$subexpression$1", "symbols": ["array"]},
    {"name": "device", "symbols": [{"literal":"DEVICE"}, {"literal":"("}, "device$subexpression$1", {"literal":")"}], "postprocess":  (d) => {
            return {
                _bsInternal: true,
                operator: "device",
                deviceTypes:d[2][0]
            }
        }
          },
    {"name": "enrollmentUrl", "symbols": [{"literal":"ENROLLMENT_URL"}], "postprocess":  (d) => {
            return {
                _bsInternal: true,
                operator: "enrollmentUrl"
            }
        }
        },
    {"name": "field", "symbols": ["absField"], "postprocess": id},
    {"name": "line", "symbols": ["absLine"], "postprocess": id},
    {"name": "line", "symbols": ["relLine"], "postprocess": id},
    {"name": "objectPointer", "symbols": ["absObject"], "postprocess": id},
    {"name": "objectPointer", "symbols": ["relObject"], "postprocess": id},
    {"name": "lineField", "symbols": ["absLineField"], "postprocess": id},
    {"name": "lineField", "symbols": ["relLineField"], "postprocess": id},
    {"name": "objectField", "symbols": ["absObjectField"], "postprocess": id},
    {"name": "objectField", "symbols": ["relObjectField"], "postprocess": id},
    {"name": "absField", "symbols": [{"literal":"F"}, {"literal":"["}, "string", {"literal":"]"}], "postprocess":  (d) => {
            const fieldName = d[2];
            return {
                _bsInternal: true,
                pointerType: "field",
                valuePath: fieldName,
                schemaPath: `fields.${fieldName}`,
                expression: `F["${fieldName}"]`
            }
        }
         },
    {"name": "absObject$ebnf$1", "symbols": []},
    {"name": "absObject$ebnf$1$subexpression$1$subexpression$1", "symbols": ["absLine"]},
    {"name": "absObject$ebnf$1$subexpression$1$subexpression$1", "symbols": ["absObject"]},
    {"name": "absObject$ebnf$1$subexpression$1", "symbols": [(lexer.has("separator") ? {type: "separator"} : separator), "absObject$ebnf$1$subexpression$1$subexpression$1"]},
    {"name": "absObject$ebnf$1", "symbols": ["absObject$ebnf$1", "absObject$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "absObject", "symbols": [{"literal":"O"}, {"literal":"["}, "string", {"literal":"]"}, "absObject$ebnf$1"], "postprocess":  (d) => {
        
          let valuePath = `${d[2]}`;
          let schemaPath = `objects.${d[2]}`;
          let expression = `O[${d[2]}]`;
          let lineIds = undefined;
          const nestedEntityInfo = d[4];
          nestedEntityInfo.forEach(nestedEntityInfo => {
              const nestedEntity = nestedEntityInfo[1][0];
              const nestedExpression = nestedEntity.expression;
              const nestedValuePath = nestedEntity.valuePath;
              const nestedSchemaPath = nestedEntity.schemaPath;
        
              if(nestedEntity.lineIds){
                lineIds = lineIds || [];
                lineIds = lineIds.concat(nestedEntity.lineIds);
              }
              valuePath = `${valuePath}.${nestedValuePath}`;
              schemaPath = `${schemaPath}.${nestedSchemaPath}`;
              expression = `${expression}.${nestedExpression}`;
          })
          return {
            _bsInternal: true,
            pointerType: 'object',
            valuePath,
            schemaPath,
            lineIds,
            expression
          };
        }
        },
    {"name": "absObjectField", "symbols": ["absObject", (lexer.has("separator") ? {type: "separator"} : separator), "absField"], "postprocess":  (d) => {
          return Object.assign(d[0], {
            pointerType: 'objectField',
            valuePath: `${d[0].valuePath}.${d[2].valuePath}`,
            schemaPath: `${d[0].schemaPath}.${d[2].schemaPath}`,
            expression: `${d[0].expression}.${d[2].expression}`
          });
        }
        },
    {"name": "relObject", "symbols": [{"literal":"SRO"}], "postprocess":  (d) => {
          return {
            _bsInternal: true,
            pointerType: 'sourceObject',
            valuePath: ``,
            schemaPath: ``,
            expression: `SRO`
          };
        }
        },
    {"name": "relObjectField$subexpression$1", "symbols": ["absField"]},
    {"name": "relObjectField$subexpression$1", "symbols": ["absLineField"]},
    {"name": "relObjectField$subexpression$1", "symbols": ["absObjectField"]},
    {"name": "relObjectField", "symbols": ["relObject", (lexer.has("separator") ? {type: "separator"} : separator), "relObjectField$subexpression$1"], "postprocess":  (d) => {
            const reference = {
                _bsInternal: true,
                pointerType: 'sourceObjectField',
                valuePath: d[2][0].valuePath,
                schemaPath: d[2][0].schemaPath,
                expression: `${d[0].expression}.${d[2][0].expression}`
            };
            if(d[2][0].lineIds){
                reference.lineIds = d[2][0].lineIds
            }
            return reference;
        }
        },
    {"name": "absLine$subexpression$1", "symbols": ["string"]},
    {"name": "absLine$subexpression$1", "symbols": ["number"]},
    {"name": "absLine$subexpression$1", "symbols": [{"literal":"*"}]},
    {"name": "absLine$ebnf$1", "symbols": []},
    {"name": "absLine$ebnf$1$subexpression$1$subexpression$1", "symbols": ["absLine"]},
    {"name": "absLine$ebnf$1$subexpression$1$subexpression$1", "symbols": ["absObject"]},
    {"name": "absLine$ebnf$1$subexpression$1", "symbols": [(lexer.has("separator") ? {type: "separator"} : separator), "absLine$ebnf$1$subexpression$1$subexpression$1"]},
    {"name": "absLine$ebnf$1", "symbols": ["absLine$ebnf$1", "absLine$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "absLine", "symbols": [{"literal":"L"}, {"literal":"["}, "_", "string", "_", {"literal":"]"}, {"literal":"["}, "_", "absLine$subexpression$1", "_", {"literal":"]"}, "absLine$ebnf$1"], "postprocess":  (d) => {
            const firstLineName = d[3];
            const firstLineIndex = (d[8][0].text === '*') ? '*' : d[8][0];
            const firstLineIndexExpression =
                (typeof firstLineIndex === 'number' || firstLineIndex === '*') ?
                firstLineIndex : `"${firstLineIndex}"`;
            let schemaPath = `lines.${firstLineName}`;
            let valuePath = `${firstLineName}.${firstLineIndex}`
            let lineIds = [firstLineIndex];
            let expression = `L["${firstLineName}"][${firstLineIndexExpression}]`;
        
            const nestedEntityInfo = d[11];
            nestedEntityInfo.forEach(nestedEntityInfo => {
                const nestedEntity = nestedEntityInfo[1][0];
                const nestedExpression = nestedEntity.expression;
                const nestedValuePath = nestedEntity.valuePath;
                const nestedSchemaPath = nestedEntity.schemaPath;
        
                if(nestedEntity.lineIds){
                  lineIds = lineIds.concat(nestedEntity.lineIds);
                }
                valuePath = `${valuePath}.${nestedValuePath}`;
                schemaPath = `${schemaPath}.${nestedSchemaPath}`;
                expression = `${expression}.${nestedExpression}`;
            })
        
            return {
                _bsInternal: true,
                pointerType: 'line',
                valuePath,
                schemaPath,
                lineIds,
                expression
            }
        }
        },
    {"name": "relLine", "symbols": [{"literal":"SRL"}, (lexer.has("separator") ? {type: "separator"} : separator), "absLine"], "postprocess":  (d) => ({
            _bsInternal: true,
            pointerType: 'sourceLine',
            valuePath: d[2].valuePath,
            schemaPath: d[2].schemaPath,
            lineIds: d[2].lineIds,
            expression: `SRL.${d[2].expression}`
        })
        },
    {"name": "absLineField", "symbols": ["absLine", (lexer.has("separator") ? {type: "separator"} : separator), "absField"], "postprocess":  (d) => ({
            _bsInternal: true,
            pointerType: 'lineField',
            valuePath: `${d[0].valuePath}.${d[2].valuePath}`,
            schemaPath: `${d[0].schemaPath}.${d[2].schemaPath}`,
            lineIds: d[0].lineIds,
            expression: `${d[0].expression}.${d[2].expression}`
        })
        },
    {"name": "relLineField$subexpression$1", "symbols": ["absField"]},
    {"name": "relLineField$subexpression$1", "symbols": ["absLineField"]},
    {"name": "relLineField$subexpression$1", "symbols": ["absObjectField"]},
    {"name": "relLineField", "symbols": [{"literal":"SRL"}, (lexer.has("separator") ? {type: "separator"} : separator), "relLineField$subexpression$1"], "postprocess":  (d) => {
            const reference = {
                _bsInternal: true,
                pointerType: 'sourceLineField',
                valuePath: d[2][0].valuePath,
                schemaPath: d[2][0].schemaPath,
                expression: `SRL.${d[2][0].expression}`
            }
            if(d[2][0].lineIds){
                reference.lineIds = d[2][0].lineIds
            }
            return reference;
        }
        },
    {"name": "reference", "symbols": ["absReference"], "postprocess": id},
    {"name": "reference", "symbols": ["absObjectReference"], "postprocess": id},
    {"name": "reference", "symbols": ["relObjectReference"], "postprocess": id},
    {"name": "reference", "symbols": ["absLineReference"], "postprocess": id},
    {"name": "reference", "symbols": ["relLineReference"], "postprocess": id},
    {"name": "absReference$subexpression$1", "symbols": ["absReference"]},
    {"name": "absReference$subexpression$1", "symbols": ["absField"]},
    {"name": "absReference$subexpression$1", "symbols": ["absLineField"]},
    {"name": "absReference$subexpression$1", "symbols": ["absObjectField"]},
    {"name": "absReference", "symbols": [{"literal":"REF"}, {"literal":"["}, "string", {"literal":"]"}, (lexer.has("separator") ? {type: "separator"} : separator), "absReference$subexpression$1"], "postprocess":  (d) => {
            const fieldName = d[2];
            return {
                _bsInternal: true,
                pointerType: "field",
                isReference: true,
                valuePath: fieldName,
                schemaPath: `fields.${fieldName}`,
                expression: `REF["${fieldName}"].${d[5][0].expression}`,
                unresolvedExpressionPart: d[5][0].expression
            }
        }
         },
    {"name": "absLineReference", "symbols": ["absLine", (lexer.has("separator") ? {type: "separator"} : separator), "absReference"], "postprocess":  (d) => ({
            _bsInternal: true,
            pointerType: 'lineField',
            isReference: true,
            valuePath: `${d[0].valuePath}.${d[2].valuePath}`,
            schemaPath: `${d[0].schemaPath}.${d[2].schemaPath}`,
            lineIds: d[0].lineIds,
            expression: `${d[0].expression}.${d[2].expression}`,
            unresolvedExpressionPart: d[2].unresolvedExpressionPart
        })
        },
    {"name": "relLineReference$subexpression$1", "symbols": ["absReference"]},
    {"name": "relLineReference$subexpression$1", "symbols": ["absLineReference"]},
    {"name": "relLineReference$subexpression$1", "symbols": ["absObjectReference"]},
    {"name": "relLineReference", "symbols": [{"literal":"SRL"}, (lexer.has("separator") ? {type: "separator"} : separator), "relLineReference$subexpression$1"], "postprocess":  (d) => {
            const reference = {
                _bsInternal: true,
                isReference: true,
                pointerType: 'sourceLineField',
                valuePath: d[2][0].valuePath,
                schemaPath: d[2][0].schemaPath,
                expression: `SRL.${d[2][0].expression}`,
                unresolvedExpressionPart: d[2][0].unresolvedExpressionPart
            }
            if(d[2][0].lineIds){
                reference.lineIds = d[2][0].lineIds
            }
            return reference;
        }
        },
    {"name": "absObjectReference", "symbols": ["absObject", (lexer.has("separator") ? {type: "separator"} : separator), "absReference"], "postprocess":  (d) => ({
           _bsInternal: true,
           pointerType: 'objectField',
           isReference: true,
           valuePath: `${d[0].valuePath}.${d[2].valuePath}`,
           schemaPath: `${d[0].schemaPath}.${d[2].schemaPath}`,
           expression: `${d[0].expression}.${d[2].expression}`,
           unresolvedExpressionPart: d[2].unresolvedExpressionPart
        })
        },
    {"name": "relObjectReference$subexpression$1", "symbols": ["absReference"]},
    {"name": "relObjectReference$subexpression$1", "symbols": ["absLineReference"]},
    {"name": "relObjectReference$subexpression$1", "symbols": ["absObjectReference"]},
    {"name": "relObjectReference", "symbols": [{"literal":"SRO"}, (lexer.has("separator") ? {type: "separator"} : separator), "relObjectReference$subexpression$1"], "postprocess":  (d) => {
            const reference = {
                _bsInternal: true,
                isReference: true,
                pointerType: 'sourceObjectField',
                valuePath: d[2][0].valuePath,
                schemaPath: d[2][0].schemaPath,
                expression: `SRO.${d[2][0].expression}`,
                unresolvedExpressionPart: d[2][0].unresolvedExpressionPart
            }
            if(d[2][0].lineIds){
                reference.lineIds = d[2][0].lineIds
            }
            return reference;
        }
        },
    {"name": "parentheses", "symbols": ["astValue"], "postprocess": id},
    {"name": "exponent$macrocall$2", "symbols": ["exponent"]},
    {"name": "exponent$macrocall$3", "symbols": [{"literal":"^"}]},
    {"name": "exponent$macrocall$4", "symbols": ["parentheses"]},
    {"name": "exponent$macrocall$1", "symbols": ["exponent$macrocall$2", "_", "exponent$macrocall$3", "_", "exponent$macrocall$4"], "postprocess":  (d) => {
        let operator = '';
        d[2].forEach(operatorPart => {
          operator = (operator === '') ? operatorPart.value : `${operator}${operatorPart.value}`;
        })
        return {
          _bsInternal: true,
          operator,
          left: d[0][0],
          right: d[4][0]
        }}
        },
    {"name": "exponent", "symbols": ["exponent$macrocall$1"], "postprocess": id},
    {"name": "exponent", "symbols": ["parentheses"], "postprocess": id},
    {"name": "multiplicationDivision$macrocall$2", "symbols": ["multiplicationDivision"]},
    {"name": "multiplicationDivision$macrocall$3", "symbols": [{"literal":"*"}]},
    {"name": "multiplicationDivision$macrocall$4", "symbols": ["exponent"]},
    {"name": "multiplicationDivision$macrocall$1", "symbols": ["multiplicationDivision$macrocall$2", "_", "multiplicationDivision$macrocall$3", "_", "multiplicationDivision$macrocall$4"], "postprocess":  (d) => {
        let operator = '';
        d[2].forEach(operatorPart => {
          operator = (operator === '') ? operatorPart.value : `${operator}${operatorPart.value}`;
        })
        return {
          _bsInternal: true,
          operator,
          left: d[0][0],
          right: d[4][0]
        }}
        },
    {"name": "multiplicationDivision", "symbols": ["multiplicationDivision$macrocall$1"], "postprocess": id},
    {"name": "multiplicationDivision$macrocall$6", "symbols": ["multiplicationDivision"]},
    {"name": "multiplicationDivision$macrocall$7", "symbols": [{"literal":"/"}]},
    {"name": "multiplicationDivision$macrocall$8", "symbols": ["exponent"]},
    {"name": "multiplicationDivision$macrocall$5", "symbols": ["multiplicationDivision$macrocall$6", "_", "multiplicationDivision$macrocall$7", "_", "multiplicationDivision$macrocall$8"], "postprocess":  (d) => {
        let operator = '';
        d[2].forEach(operatorPart => {
          operator = (operator === '') ? operatorPart.value : `${operator}${operatorPart.value}`;
        })
        return {
          _bsInternal: true,
          operator,
          left: d[0][0],
          right: d[4][0]
        }}
        },
    {"name": "multiplicationDivision", "symbols": ["multiplicationDivision$macrocall$5"], "postprocess": id},
    {"name": "multiplicationDivision", "symbols": ["exponent"], "postprocess": id},
    {"name": "additionSubtraction$macrocall$2", "symbols": ["additionSubtraction"]},
    {"name": "additionSubtraction$macrocall$3", "symbols": [{"literal":"+"}]},
    {"name": "additionSubtraction$macrocall$4", "symbols": ["multiplicationDivision"]},
    {"name": "additionSubtraction$macrocall$1", "symbols": ["additionSubtraction$macrocall$2", "_", "additionSubtraction$macrocall$3", "_", "additionSubtraction$macrocall$4"], "postprocess":  (d) => {
        let operator = '';
        d[2].forEach(operatorPart => {
          operator = (operator === '') ? operatorPart.value : `${operator}${operatorPart.value}`;
        })
        return {
          _bsInternal: true,
          operator,
          left: d[0][0],
          right: d[4][0]
        }}
        },
    {"name": "additionSubtraction", "symbols": ["additionSubtraction$macrocall$1"], "postprocess": id},
    {"name": "additionSubtraction$macrocall$6", "symbols": ["additionSubtraction"]},
    {"name": "additionSubtraction$macrocall$7", "symbols": [{"literal":"-"}]},
    {"name": "additionSubtraction$macrocall$8", "symbols": ["multiplicationDivision"]},
    {"name": "additionSubtraction$macrocall$5", "symbols": ["additionSubtraction$macrocall$6", "_", "additionSubtraction$macrocall$7", "_", "additionSubtraction$macrocall$8"], "postprocess":  (d) => {
        let operator = '';
        d[2].forEach(operatorPart => {
          operator = (operator === '') ? operatorPart.value : `${operator}${operatorPart.value}`;
        })
        return {
          _bsInternal: true,
          operator,
          left: d[0][0],
          right: d[4][0]
        }}
        },
    {"name": "additionSubtraction", "symbols": ["additionSubtraction$macrocall$5"], "postprocess": id},
    {"name": "additionSubtraction", "symbols": ["multiplicationDivision"], "postprocess": id},
    {"name": "concatenate$macrocall$2", "symbols": ["expr"]},
    {"name": "concatenate$macrocall$3", "symbols": [{"literal":"&"}]},
    {"name": "concatenate$macrocall$4", "symbols": ["expr"]},
    {"name": "concatenate$macrocall$1", "symbols": ["concatenate$macrocall$2", "_", "concatenate$macrocall$3", "_", "concatenate$macrocall$4"], "postprocess":  (d) => {
        let operator = '';
        d[2].forEach(operatorPart => {
          operator = (operator === '') ? operatorPart.value : `${operator}${operatorPart.value}`;
        })
        return {
          _bsInternal: true,
          operator,
          left: d[0][0],
          right: d[4][0]
        }}
        },
    {"name": "concatenate", "symbols": ["concatenate$macrocall$1"], "postprocess": id},
    {"name": "comparisonOperator$macrocall$2", "symbols": ["expr"]},
    {"name": "comparisonOperator$macrocall$3", "symbols": [{"literal":"="}]},
    {"name": "comparisonOperator$macrocall$4", "symbols": ["expr"]},
    {"name": "comparisonOperator$macrocall$1", "symbols": ["comparisonOperator$macrocall$2", "_", "comparisonOperator$macrocall$3", "_", "comparisonOperator$macrocall$4"], "postprocess":  (d) => {
        let operator = '';
        d[2].forEach(operatorPart => {
          operator = (operator === '') ? operatorPart.value : `${operator}${operatorPart.value}`;
        })
        return {
          _bsInternal: true,
          operator,
          left: d[0][0],
          right: d[4][0]
        }}
        },
    {"name": "comparisonOperator", "symbols": ["comparisonOperator$macrocall$1"], "postprocess": id},
    {"name": "comparisonOperator$macrocall$6", "symbols": ["expr"]},
    {"name": "comparisonOperator$macrocall$7", "symbols": [{"literal":"<"}, {"literal":">"}]},
    {"name": "comparisonOperator$macrocall$8", "symbols": ["expr"]},
    {"name": "comparisonOperator$macrocall$5", "symbols": ["comparisonOperator$macrocall$6", "_", "comparisonOperator$macrocall$7", "_", "comparisonOperator$macrocall$8"], "postprocess":  (d) => {
        let operator = '';
        d[2].forEach(operatorPart => {
          operator = (operator === '') ? operatorPart.value : `${operator}${operatorPart.value}`;
        })
        return {
          _bsInternal: true,
          operator,
          left: d[0][0],
          right: d[4][0]
        }}
        },
    {"name": "comparisonOperator", "symbols": ["comparisonOperator$macrocall$5"], "postprocess": id},
    {"name": "comparisonOperator$macrocall$10", "symbols": ["expr"]},
    {"name": "comparisonOperator$macrocall$11", "symbols": [{"literal":">"}]},
    {"name": "comparisonOperator$macrocall$12", "symbols": ["expr"]},
    {"name": "comparisonOperator$macrocall$9", "symbols": ["comparisonOperator$macrocall$10", "_", "comparisonOperator$macrocall$11", "_", "comparisonOperator$macrocall$12"], "postprocess":  (d) => {
        let operator = '';
        d[2].forEach(operatorPart => {
          operator = (operator === '') ? operatorPart.value : `${operator}${operatorPart.value}`;
        })
        return {
          _bsInternal: true,
          operator,
          left: d[0][0],
          right: d[4][0]
        }}
        },
    {"name": "comparisonOperator", "symbols": ["comparisonOperator$macrocall$9"], "postprocess": id},
    {"name": "comparisonOperator$macrocall$14", "symbols": ["expr"]},
    {"name": "comparisonOperator$macrocall$15", "symbols": [{"literal":">"}, {"literal":"="}]},
    {"name": "comparisonOperator$macrocall$16", "symbols": ["expr"]},
    {"name": "comparisonOperator$macrocall$13", "symbols": ["comparisonOperator$macrocall$14", "_", "comparisonOperator$macrocall$15", "_", "comparisonOperator$macrocall$16"], "postprocess":  (d) => {
        let operator = '';
        d[2].forEach(operatorPart => {
          operator = (operator === '') ? operatorPart.value : `${operator}${operatorPart.value}`;
        })
        return {
          _bsInternal: true,
          operator,
          left: d[0][0],
          right: d[4][0]
        }}
        },
    {"name": "comparisonOperator", "symbols": ["comparisonOperator$macrocall$13"], "postprocess": id},
    {"name": "comparisonOperator$macrocall$18", "symbols": ["expr"]},
    {"name": "comparisonOperator$macrocall$19", "symbols": [{"literal":"<"}]},
    {"name": "comparisonOperator$macrocall$20", "symbols": ["expr"]},
    {"name": "comparisonOperator$macrocall$17", "symbols": ["comparisonOperator$macrocall$18", "_", "comparisonOperator$macrocall$19", "_", "comparisonOperator$macrocall$20"], "postprocess":  (d) => {
        let operator = '';
        d[2].forEach(operatorPart => {
          operator = (operator === '') ? operatorPart.value : `${operator}${operatorPart.value}`;
        })
        return {
          _bsInternal: true,
          operator,
          left: d[0][0],
          right: d[4][0]
        }}
        },
    {"name": "comparisonOperator", "symbols": ["comparisonOperator$macrocall$17"], "postprocess": id},
    {"name": "comparisonOperator$macrocall$22", "symbols": ["expr"]},
    {"name": "comparisonOperator$macrocall$23", "symbols": [{"literal":"<"}, {"literal":"="}]},
    {"name": "comparisonOperator$macrocall$24", "symbols": ["expr"]},
    {"name": "comparisonOperator$macrocall$21", "symbols": ["comparisonOperator$macrocall$22", "_", "comparisonOperator$macrocall$23", "_", "comparisonOperator$macrocall$24"], "postprocess":  (d) => {
        let operator = '';
        d[2].forEach(operatorPart => {
          operator = (operator === '') ? operatorPart.value : `${operator}${operatorPart.value}`;
        })
        return {
          _bsInternal: true,
          operator,
          left: d[0][0],
          right: d[4][0]
        }}
        },
    {"name": "comparisonOperator", "symbols": ["comparisonOperator$macrocall$21"], "postprocess": id},
    {"name": "arrayOperator$subexpression$1", "symbols": ["field"]},
    {"name": "arrayOperator$subexpression$1", "symbols": ["lineField"]},
    {"name": "arrayOperator$subexpression$1", "symbols": ["array"]},
    {"name": "arrayOperator$subexpression$1", "symbols": ["formatOperator"]},
    {"name": "arrayOperator$subexpression$1", "symbols": ["arrayOutputOperator"]},
    {"name": "arrayOperator$ebnf$1", "symbols": []},
    {"name": "arrayOperator$ebnf$1$subexpression$1$subexpression$1", "symbols": ["field"]},
    {"name": "arrayOperator$ebnf$1$subexpression$1$subexpression$1", "symbols": ["lineField"]},
    {"name": "arrayOperator$ebnf$1$subexpression$1$subexpression$1", "symbols": ["array"]},
    {"name": "arrayOperator$ebnf$1$subexpression$1$subexpression$1", "symbols": ["formatOperator"]},
    {"name": "arrayOperator$ebnf$1$subexpression$1$subexpression$1", "symbols": ["arrayOutputOperator"]},
    {"name": "arrayOperator$ebnf$1$subexpression$1", "symbols": ["_", {"literal":","}, "_", "arrayOperator$ebnf$1$subexpression$1$subexpression$1"]},
    {"name": "arrayOperator$ebnf$1", "symbols": ["arrayOperator$ebnf$1", "arrayOperator$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "arrayOperator", "symbols": [(lexer.has("arrayOperator") ? {type: "arrayOperator"} : arrayOperator), {"literal":"("}, "_", "arrayOperator$subexpression$1", "arrayOperator$ebnf$1", "_", {"literal":")"}], "postprocess":  (d) => {
          const additionalArrays = d[4].map(arrayInfo => arrayInfo[3][0]);
          return{
            _bsInternal: true,
            operator: d[0].value,
            arrays: d[3].concat(additionalArrays)
          }
        }
        },
    {"name": "arrayOutputOperator$subexpression$1", "symbols": ["lineField"]},
    {"name": "arrayOutputOperator$subexpression$1", "symbols": ["array"]},
    {"name": "arrayOutputOperator$subexpression$1", "symbols": ["formatOperator"]},
    {"name": "arrayOutputOperator$subexpression$1", "symbols": ["arrayOutputOperator"]},
    {"name": "arrayOutputOperator$ebnf$1", "symbols": []},
    {"name": "arrayOutputOperator$ebnf$1$subexpression$1$subexpression$1", "symbols": ["lineField"]},
    {"name": "arrayOutputOperator$ebnf$1$subexpression$1$subexpression$1", "symbols": ["array"]},
    {"name": "arrayOutputOperator$ebnf$1$subexpression$1$subexpression$1", "symbols": ["formatOperator"]},
    {"name": "arrayOutputOperator$ebnf$1$subexpression$1$subexpression$1", "symbols": ["arrayOutputOperator"]},
    {"name": "arrayOutputOperator$ebnf$1$subexpression$1", "symbols": ["_", {"literal":","}, "_", "arrayOutputOperator$ebnf$1$subexpression$1$subexpression$1"]},
    {"name": "arrayOutputOperator$ebnf$1", "symbols": ["arrayOutputOperator$ebnf$1", "arrayOutputOperator$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "arrayOutputOperator", "symbols": [(lexer.has("arrayOutputOperator") ? {type: "arrayOutputOperator"} : arrayOutputOperator), {"literal":"("}, "_", "arrayOutputOperator$subexpression$1", "arrayOutputOperator$ebnf$1", "_", {"literal":")"}], "postprocess":  (d) => {
          const additionalArrays = d[4].map(arrayInfo => arrayInfo[3][0]);
          return{
            _bsInternal: true,
            operator: d[0].value,
            arrays: d[3].concat(additionalArrays)
          }
        }
        },
    {"name": "arrayOperator", "symbols": ["arrayOutputOperator"], "postprocess": id},
    {"name": "arrayOperator", "symbols": [{"literal":"COUNT"}, {"literal":"("}, "_", "astValue", "_", {"literal":")"}], "postprocess":  (d) => ({
          _bsInternal: true,
          operator: d[0].value,
          array: d[3]
        })
        },
    {"name": "indexOperator$subexpression$1", "symbols": ["expr"]},
    {"name": "indexOperator", "symbols": [{"literal":"INDEX"}, {"literal":"("}, "_", "indexOperator$subexpression$1", "_", {"literal":","}, "_", "expr", "_", {"literal":")"}], "postprocess":  (d) => {
          return{
            _bsInternal: true,
            operator: d[0].value,
            arrays: d[3],
            index: d[7]
          }
        }
        },
    {"name": "arrayOperator$subexpression$2$subexpression$1", "symbols": ["field"]},
    {"name": "arrayOperator$subexpression$2$subexpression$1", "symbols": ["lineField"]},
    {"name": "arrayOperator$subexpression$2$subexpression$1", "symbols": ["objectField"]},
    {"name": "arrayOperator$subexpression$2", "symbols": [{"literal":"["}, "_", "arrayOperator$subexpression$2$subexpression$1", "_", {"literal":","}, "_", "expr", "_", {"literal":"]"}]},
    {"name": "arrayOperator$ebnf$2", "symbols": []},
    {"name": "arrayOperator$ebnf$2$subexpression$1$subexpression$1", "symbols": ["field"]},
    {"name": "arrayOperator$ebnf$2$subexpression$1$subexpression$1", "symbols": ["lineField"]},
    {"name": "arrayOperator$ebnf$2$subexpression$1$subexpression$1", "symbols": ["objectField"]},
    {"name": "arrayOperator$ebnf$2$subexpression$1", "symbols": [{"literal":","}, "_", {"literal":"["}, "_", "arrayOperator$ebnf$2$subexpression$1$subexpression$1", "_", {"literal":","}, "_", "expr", "_", {"literal":"]"}]},
    {"name": "arrayOperator$ebnf$2", "symbols": ["arrayOperator$ebnf$2", "arrayOperator$ebnf$2$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "arrayOperator", "symbols": [{"literal":"SETVALUES"}, {"literal":"("}, "_", "arrayOperator$subexpression$2", "_", "arrayOperator$ebnf$2", "_", {"literal":")"}], "postprocess":  (d) => {
          const keyValuePairs = [];
          keyValuePairs.push([d[3][2][0], d[3][6]]);
        
          d[5].forEach(keyValuePair => {
            keyValuePairs.push([keyValuePair[4][0], keyValuePair[8]]);
          })
        
        	return {
        		_bsInternal: true,
            operator: d[0].value,
            keyValuePairs
        	}
        }
        },
    {"name": "conditionalArrayOperator", "symbols": [(lexer.has("conditionalArrayOperator") ? {type: "conditionalArrayOperator"} : conditionalArrayOperator), {"literal":"("}, "_", "expr", "_", {"literal":","}, "_", "expr", "_", {"literal":")"}], "postprocess":  (d) => ({
          _bsInternal: true,
          operator: d[0].value,
          array: d[3],
          condition: annotateConditionAST(d[3], d[7])
        })
        },
    {"name": "textJoinOperator", "symbols": [{"literal":"TEXTJOIN"}, {"literal":"("}, "_", "string", "_", {"literal":","}, "_", "expr", "_", {"literal":","}, "_", "expr", "_", {"literal":")"}], "postprocess":  (d) => ({
          _bsInternal: true,
          operator: d[0].value,
          separator1: d[3],
          ignoreEmpty: d[7],
          arrays: [d[11]]
        })
        },
    {"name": "textJoinOperator$ebnf$1$subexpression$1", "symbols": ["_", {"literal":","}, "_", "expr"]},
    {"name": "textJoinOperator$ebnf$1", "symbols": ["textJoinOperator$ebnf$1$subexpression$1"]},
    {"name": "textJoinOperator$ebnf$1$subexpression$2", "symbols": ["_", {"literal":","}, "_", "expr"]},
    {"name": "textJoinOperator$ebnf$1", "symbols": ["textJoinOperator$ebnf$1", "textJoinOperator$ebnf$1$subexpression$2"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "textJoinOperator", "symbols": [{"literal":"ZIPTEXTJOIN"}, {"literal":"("}, "_", "string", "_", {"literal":","}, "_", "string", "_", {"literal":","}, "_", "expr", "_", {"literal":","}, "_", "expr", "textJoinOperator$ebnf$1", "_", {"literal":")"}], "postprocess":  (d) => {
        const arrays = [d[15]];
        d[16].forEach(array => {
          arrays.push(array[3]);
        });
        return {
          _bsInternal: true,
          operator: d[0].value,
          separator1: d[3],
          separator2: d[7],
          ignoreEmpty: d[11],
          arrays
        }}
        },
    {"name": "booleanOperator", "symbols": ["andOrOperator"], "postprocess": id},
    {"name": "booleanOperator", "symbols": ["isBlankNotExistsOperator"], "postprocess": id},
    {"name": "booleanOperator", "symbols": ["ifOperator"], "postprocess": id},
    {"name": "booleanOperator", "symbols": ["includesOperator"], "postprocess": id},
    {"name": "andOrOperator$subexpression$1", "symbols": [{"literal":"AND"}]},
    {"name": "andOrOperator$subexpression$1", "symbols": [{"literal":"OR"}]},
    {"name": "andOrOperator$ebnf$1", "symbols": []},
    {"name": "andOrOperator$ebnf$1$subexpression$1", "symbols": [{"literal":","}, "_", "expr", "_"]},
    {"name": "andOrOperator$ebnf$1", "symbols": ["andOrOperator$ebnf$1", "andOrOperator$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "andOrOperator", "symbols": ["andOrOperator$subexpression$1", {"literal":"("}, "_", "expr", "_", "andOrOperator$ebnf$1", {"literal":")"}], "postprocess":  (d) => {
        var conditions = [d[3]];
        d[5].forEach(condition => {
          conditions.push(condition[2]);
        });
        
        return{
          _bsInternal: true,
          operator: d[0][0].value,
          conditions
        }}
        },
    {"name": "isBlankNotExistsOperator$subexpression$1", "symbols": [{"literal":"ISBLANK"}]},
    {"name": "isBlankNotExistsOperator$subexpression$1", "symbols": [{"literal":"NOT"}]},
    {"name": "isBlankNotExistsOperator$subexpression$1", "symbols": [{"literal":"EXISTS"}]},
    {"name": "isBlankNotExistsOperator", "symbols": ["isBlankNotExistsOperator$subexpression$1", {"literal":"("}, "_", "expr", "_", {"literal":")"}], "postprocess":  (d) => ({
          _bsInternal: true,
          operator: d[0][0].value,
          left: d[3]
        })
        },
    {"name": "ifOperator", "symbols": [{"literal":"IF"}, {"literal":"("}, "_", "expr", "_", {"literal":","}, "_", "expr", "_", {"literal":","}, "_", "expr", "_", {"literal":")"}], "postprocess":  (d) => ({
          _bsInternal: true,
          operator: d[0].value,
          left: d[3],
          right: d[7],
          last: d[11]
        })
        },
    {"name": "includesOperator", "symbols": [{"literal":"INCLUDES"}, {"literal":"("}, "_", "expr", "_", {"literal":","}, "_", "expr", {"literal":")"}], "postprocess":  (d) => ({
          _bsInternal: true,
          operator: d[0].value,
          left: d[3],
          right: d[7]
        })
        },
    {"name": "formatOperator", "symbols": [{"literal":"FORMAT"}, {"literal":"("}, "_", "expr", "_", {"literal":","}, "_", "string", "_", {"literal":")"}], "postprocess":  (data) => {
          return {
              _bsInternal: true,
              unresolvedExpression: data[3],
              operator: data[0].value,
              format: data[7],
          };
        }
        },
    {"name": "labelOperator", "symbols": [{"literal":"LABEL"}, {"literal":"("}, "_", "field", "_", {"literal":")"}], "postprocess":  (data) => {
          return {
              _bsInternal: true,
              unresolvedExpression: data[3],
              operator: data[0].value,
          };
        }
        },
    {"name": "filterOperator", "symbols": [{"literal":"FILTER"}, {"literal":"("}, "_", "string", "_", {"literal":","}, "_", "string", "_", {"literal":")"}], "postprocess":  (d) => {
          return {
              _bsInternal:true,
              documentDefinitionName: d[3],
              unresolvedExpression: d[7],
              operator: d[0].value,
          };
        }
        },
    {"name": "dateExpressions", "symbols": ["nowStatement"], "postprocess": id},
    {"name": "dateExpressions", "symbols": ["todayStatement"], "postprocess": id},
    {"name": "dateExpressions", "symbols": ["timeStatement"], "postprocess": id},
    {"name": "dateExpressions", "symbols": ["dateStatement"], "postprocess": id},
    {"name": "dateExpressions", "symbols": ["timeValueStatement"], "postprocess": id},
    {"name": "nowStatement", "symbols": [{"literal":"NOW"}, {"literal":"("}, {"literal":")"}], "postprocess":  d => ({
          _bsInternal: true,
          expression: "now"
        }) },
    {"name": "todayStatement", "symbols": [{"literal":"TODAY"}, {"literal":"("}, {"literal":")"}], "postprocess":  d => ({
          _bsInternal: true,
          expression: "today"
        }) },
    {"name": "timeStatement", "symbols": [{"literal":"TIME"}, {"literal":"("}, "number", {"literal":","}, "number", {"literal":")"}], "postprocess":  d => ({
          _bsInternal: true,
          expression: "time",
          hour: d[2],
          minute: d[4]
        })},
    {"name": "dateStatement", "symbols": [{"literal":"DATE"}, {"literal":"("}, "number", {"literal":","}, "number", {"literal":","}, "number", {"literal":")"}], "postprocess":  d => (
          {
            _bsInternal: true,
            expression: "date",
            year: d[2],
            month: d[4],
            day: d[6]
          }
        )},
    {"name": "timeValueStatement", "symbols": [{"literal":"TIMEVALUE"}, {"literal":"("}, "expr", {"literal":")"}], "postprocess":  d => ({
          _bsInternal: true,
          expression: "timeValue",
          time: d[2]
        })},
    {"name": "main", "symbols": ["_", "expr", "_"], "postprocess": (d) => d[1]},
    {"name": "expr", "symbols": ["comparisonOperator"], "postprocess": id},
    {"name": "expr", "symbols": ["additionSubtraction"], "postprocess": id},
    {"name": "astValue", "symbols": [{"literal":"("}, "_", "expr", "_", {"literal":")"}], "postprocess": (d) => d[2]},
    {"name": "astValue$subexpression$1", "symbols": ["value"]},
    {"name": "astValue$subexpression$1", "symbols": ["systemField"]},
    {"name": "astValue$subexpression$1", "symbols": ["reference"]},
    {"name": "astValue$subexpression$1", "symbols": ["field"]},
    {"name": "astValue$subexpression$1", "symbols": ["line"]},
    {"name": "astValue$subexpression$1", "symbols": ["objectPointer"]},
    {"name": "astValue$subexpression$1", "symbols": ["lineField"]},
    {"name": "astValue$subexpression$1", "symbols": ["objectField"]},
    {"name": "astValue$subexpression$1", "symbols": ["nonMathOperator"]},
    {"name": "astValue$subexpression$1", "symbols": ["clientPointer"]},
    {"name": "astValue", "symbols": ["astValue$subexpression$1"], "postprocess": (d) => d[0][0]},
    {"name": "nonMathOperator", "symbols": ["concatenate"], "postprocess": id},
    {"name": "nonMathOperator", "symbols": ["arrayOperator"], "postprocess": id},
    {"name": "nonMathOperator", "symbols": ["booleanOperator"], "postprocess": id},
    {"name": "nonMathOperator", "symbols": ["dateExpressions"], "postprocess": id},
    {"name": "nonMathOperator", "symbols": ["indexOperator"], "postprocess": id},
    {"name": "nonMathOperator", "symbols": ["labelOperator"], "postprocess": id},
    {"name": "nonMathOperator", "symbols": ["formatOperator"], "postprocess": id},
    {"name": "nonMathOperator", "symbols": ["filterOperator"], "postprocess": id},
    {"name": "nonMathOperator", "symbols": ["conditionalArrayOperator"], "postprocess": id},
    {"name": "nonMathOperator", "symbols": ["textJoinOperator"], "postprocess": id}
]
  , ParserStart: "main"
}
if (typeof module !== 'undefined'&& typeof module.exports !== 'undefined') {
   module.exports = grammar;
} else {
   window.grammar = grammar;
}
})();
