// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".not-found{width:100vw;height:100vh;display:flex;font-family:\"Ubuntu\",sans-serif}.not-found__header{font-size:150px;color:#fff;font-weight:600}.not-found__message{font-size:32px;color:#4d4d4d;font-weight:bold;padding-top:40px;padding-bottom:40px}.not-found__block{width:50vw;height:100vh;display:flex;justify-content:center;align-items:center}.not-found__block__left{background-color:var(--header-background-color)}.not-found__block__right{background-color:#fff}.not-found__block__content{flex-direction:column;align-items:center;display:flex;width:50%}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
