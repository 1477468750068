// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".k-checkbox{width:16px;height:16px}.switch-box__wrapper .k-switch-md{font-size:1em;width:50px}.switch-box__wrapper .k-switch-on .k-switch-track{border-color:var(--header-background-color);background-color:var(--header-background-color)}.switch-box__wrapper .k-switch-md .k-rounded-full{width:15px;height:17px;border:.5px solid var(--header-background-color)}.switch-box__wrapper .k-switch-off:focus .k-switch-track{outline:none}.switch-box__wrapper .k-switch-on:focus .k-switch-track{outline:none}.switch-box__wrapper .k-switch-on.k-focus .k-switch-track{outline:none}.switch-box__wrapper .k-switch-off.k-focus .k-switch-track{outline:none}.switch-box__wrapper .k-switch-md.k-switch-on .k-switch-thumb-wrap{left:calc(100% - 10px - 0px)}.switch-box__wrapper .k-switch-md.k-switch-off .k-switch-thumb-wrap{left:9px}.switch-box__wrapper .k-switch.k-switch-md.k-rounded-full.k-switch-off .k-switch-thumb{background-color:var(--header-background-color)}.switch-box__wrapper .k-switch.k-switch-md.k-rounded-full.k-switch-off .k-rounded-full{border:.5px solid #d9d9d9}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
