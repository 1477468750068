// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".upload-component-indicator{font-family:\"Ubuntu\",sans-serif}.upload-component-indicator__header{display:flex}.upload-component-indicator__uploads{font-size:24px;flex:1}.upload-component-indicator__clear-finished span{color:rgba(0,0,255,.6117647059);cursor:pointer;flex:1;text-align:end;opacity:.8;user-select:none}.upload-component-indicator__clear-finished span:hover{opacity:1}.upload-component-indicator__uploads-list li{padding-bottom:3px;padding-top:3px}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
