// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../images/icon-arrow.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".spinner-button{position:relative;margin:0;outline:none;cursor:pointer;background-color:#e8751c;width:100%;height:48px;padding:8px 16px;box-sizing:border-box;border:0;border-radius:26px;color:#fff}.spinner-button[disabled]{cursor:default;opacity:.8}.spinner-button__icon{position:absolute;left:calc(50% - 35px);top:15px;width:30px;height:30px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:12px;background-repeat:no-repeat}@media(min-width: 1280px){.spinner-button__icon{top:9px}}.spinner-button__label{font-size:0.875rem}.spinner{animation:rotate 2s linear infinite;z-index:2;position:absolute;top:calc(50% - 13px);left:calc(50% - 13px);margin:0;width:26px;height:26px}.spinner .path{stroke:#f4f4f4;stroke-linecap:round;animation:dash 1.5s ease-in-out infinite}@keyframes rotate{100%{transform:rotate(360deg)}}@keyframes dash{0%{stroke-dasharray:1,150;stroke-dashoffset:0}50%{stroke-dasharray:90,150;stroke-dashoffset:-35}100%{stroke-dasharray:90,150;stroke-dashoffset:-124}}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
