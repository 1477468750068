// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".folding-tab__button{background:rgba(0,0,0,0);outline:none;border:0;padding:0;width:100%}.folding-tab__content{transition:opacity .15s ease-in;opacity:.1}.folding-tab__arrow{font-size:12px;margin-left:auto;margin-right:18px;transition:transform .2s;transform:rotate(-90deg)}.folding-tab--open .folding-tab__arrow{transform:rotate(0deg)}.folding-tab--open .folding-tab__content{opacity:1}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
