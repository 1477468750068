// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".label{font-size:14px;min-height:30px;line-height:30px;padding-right:8px;flex:0 0 152px;text-overflow:ellipsis;white-space:nowrap;overflow:hidden}.label--bold{font-weight:bold}.label--medium{font-size:1.09375rem}.label--large{font-size:24px}.label--x-large{font-size:36px}.label--gray{color:#4d4d4d}.label--no-margin{margin:0}.label--full-width{flex:100%;width:100%;padding-right:0}.label--textAlign-right{text-align:right;display:block}.label--textAlign-left{text-align:left;display:block}.label--textAlign-center{text-align:center;display:block}.label--required:after{content:\" *\";color:var(--header-background-color)}.label--no-word-wrap{white-space:unset}.label--separator-line-top{border-width:1px 0 0;border-style:solid;border-color:#cacace;padding-top:20px;margin-top:10px}.label--color-error{color:#b54040}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
