// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".env-item{background:var(--base-color);color:var(--text-color);border-radius:16px;padding:32px;display:flex;flex-flow:column;box-sizing:border-box;user-select:none;cursor:pointer;width:100%;height:256px;text-align:center;outline:none;border:0}.env-item__title{font-size:1.375rem;font-weight:300;width:100%;word-break:break-word}.env-item__subtitle{font-size:1.09375rem;line-height:20px;width:100%;margin-top:auto;word-break:break-word}.env-item__logo{width:100%;object-fit:contain;margin-top:auto}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
