// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".bizzstream-window{font-family:\"Ubuntu\",sans-serif}.bizzstream-window.k-window,.bizzstream-window.k-window-focused{box-shadow:none !important;border-radius:5px !important}.bizzstream-window .k-window-titlebar{border-top-left-radius:5px;border-top-right-radius:5px}.bizzstream-window .k-window-content{border-bottom-left-radius:5px;border-bottom-right-radius:5px}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
