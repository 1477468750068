// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".menu-block{height:100%;width:auto;display:flex}.menu-block .menu-icon-wrapper{width:20px;display:flex;align-items:center;justify-content:center;margin-right:16px}.menu-block .menu-icon-wrapper__icon{font-size:19px}.menu-block .menu-icon-wrapper__icon.k-icon{font-size:24px;margin:0}.welcome-block{height:100%;width:100%;flex:1;overflow:hidden;display:flex}.menu-block-series{height:100%;display:flex;flex-direction:row;align-items:stretch;overflow-x:scroll}.breadcrumbs{background-color:var(--breadcrumbs-background-color);height:38px;font-size:1em;line-height:38px;padding:0;left:0;position:sticky;z-index:2004;color:var(--breadcrumbs-text-color);white-space:nowrap;display:flex;align-items:center}.breadcrumbs__separator{margin:-15px}.breadcrumbs__link{color:var(--breadcrumbs-text-color);background:rgba(0,0,0,0);text-decoration:none;border:0;outline:none;margin:0 20px;padding:0}.breadcrumbs__link:hover{text-decoration:underline;cursor:pointer;color:var(--breadcrumbs-text-hover-color)}.breadcrumbs__first{font-weight:bold}.breadcrumbs__disabled{margin:0 20px;color:var(--breadcrumbs-text-active-color);overflow:hidden;text-overflow:ellipsis}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
