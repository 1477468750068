// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../images/BizzStream-Aero-Logo-WHITE.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".about.bizzstream-dialog .k-dialog-content{padding:0}.about__header{background-color:var(--header-background-color);height:150px;display:flex;justify-content:center;align-items:center}.about__info{display:flex;flex-direction:column;padding-top:20px;align-items:center;justify-content:center;height:150px}.about__info__text{width:65%;padding-top:1em}.about__info__headline{width:65%;font-weight:bold}.about__logo{height:100%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% auto;background-position:center center;background-repeat:no-repeat;width:65%}@media(min-width: 1280px){.about__logo{background-position:left center}}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
