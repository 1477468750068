// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".select-env{position:relative;margin-left:8px}.select-env--open .select-env__button{background-color:#fff;color:#262834}.select-env--open .select-env__button-icon{transform:rotate(180deg)}.select-env--open .select-env__dropdown{pointer-events:all;opacity:1}.select-env__button{cursor:pointer;background-color:rgba(0,0,0,0);color:#fff;display:flex;align-items:center;height:30px;padding:0 16px;font-size:0.875rem;border-radius:16px;border:1px solid #fff;gap:8px;transition:background-color .2s,color .2s}.select-env__button-icon{font-size:10px;transition:transform .2s}.select-env__divider{color:#262834;margin-top:7px;margin-bottom:0}.select-env__divider .title-with-line__line{width:40px}.select-env__dropdown{opacity:0;pointer-events:none;transition:opacity .2s;font-size:0.875rem;position:absolute;top:38px;right:0;border-radius:15px;width:242px;background-color:#fff;box-shadow:0 4px 8px 0 rgba(0,0,0,.08),0 2px 4px 0 rgba(0,0,0,.05)}.select-env__dropdown-content{padding:0 0 6px 0}.select-env__dropdown-search{padding:0 16px}.select-env-menu{display:flex;flex-flow:column;list-style:none;margin:10px 0 0 0;padding:0;max-height:360px;overflow:auto}.select-env-menu .user-menu__item{transition:background-color .1s}.select-env-menu .user-menu__item:hover{background-color:#f3f3f3}.select-env-menu .user-menu__link{color:#262834}.select-env-menu .user-menu__link:hover{color:#262834;background-color:rgba(0,0,0,0)}.select-env-menu .user-menu__link--active{font-weight:500}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
