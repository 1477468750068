// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".aside-menu-item{cursor:pointer;color:var(--sidebar-text-color);display:flex;align-items:center;gap:16px;padding:0 16px 0 16px;width:100%;transition:background-color .2s,color .2s;background-color:rgba(0,0,0,0);text-decoration:none;outline:none;user-select:none;overflow:hidden}.aside-menu-item:hover{background-color:var(--sidebar-selected-background-color-parent);color:var(--sidebar-selected-text-color-parent)}.aside-menu-item--main{height:48px}.aside-menu-item--main.aside-menu-item--active,.aside-menu-item--main.aside-menu-item--expanded,.aside-menu-item--main.aside-menu-item--expanded:hover{background-color:var(--sidebar-selected-background-color-parent);color:var(--sidebar-selected-text-color-parent)}.aside-menu-item--main .icon{font-size:17px}.aside-menu-item--sub{padding-left:50px;height:40px;background-color:var(--sidebar-sub-background-color);color:var(--sidebar-sub-text-color)}.aside-menu-item--sub:hover,.aside-menu-item--sub.aside-menu-item--active{background-color:var(--sidebar-selected-background-color-child);color:var(--sidebar-selected-text-color-child)}.aside-menu-item--expanded .aside-menu-item__arrow{transform:rotate(90deg)}.aside-menu-item__text{white-space:break-spaces;text-align:left;word-break:break-word;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical}.aside-menu-item__arrow{color:currentColor;margin-left:auto;display:inline-flex;align-items:center;transition:transform .2s;height:100%;padding-left:15px;padding-right:15px;margin-right:-15px}.aside-menu-item__arrow .icon{font-size:12px}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
