// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".grid__row--stretched{height:100%}.pageContent{padding-left:15px;padding-right:15px;box-sizing:border-box;background-color:#fff;height:calc(100% - 100px) !important}.pageContent .k-tabstrip .k-child-animation-container>div{height:100%}.grid-container--stretched,.grid--stretched{height:100%;width:100%}.grid{display:block;width:100%;color:#4d4d4d}.grid--white{background:#fff;border:1px solid #ccc;border-radius:4px}.grid--gray{background:#f3f3f3}.grid--dark-gray{background:#e6e6e6}.grid__row{display:flex;flex-direction:column;margin-left:5px;margin-right:5px}@media(min-width: 769px){.grid__row{flex-direction:row}}.grid__row--spacing{margin:0 20px 16px 20px}.grid__row--no-margins{margin-left:-5px;margin-right:-5px}.grid__cell{display:flex;flex-direction:column;flex-grow:1;flex-shrink:1;flex-basis:100%;padding:5px 10px;overflow:hidden}.grid__cell--gray{background:#f3f3f3}.grid__cell--white{background:#fff;border:1px solid #ccc;border-radius:4px}.grid__cell--no-margins{padding:0 5px;margin:0 !important}.grid__atom:not(.control-group){margin:0}.grid-container--no-margins .grid__row{margin:0}.grid-container--no-margins .grid__cell{padding:0 5px}.field-group-no-padding{padding:0}.disable-content{pointer-events:none}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
