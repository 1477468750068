// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".bizzstream-popup{font-family:\"Ubuntu\",sans-serif}.bizzstream-popup .k-window{margin:15px 30px}.bizzstream-popup .k-window-titlebar{background-color:#f2f3f4;color:#4d4d4d}.bizzstream-popup .k-window-content{padding:5px 10px}.bizzstream-popup .k-window-content>.grid__cell{flex:0 1 152px}.bizzstream-popup .k-window-content>.grid__cell .control-group>.label{flex:0 1 auto}.bizzstream-popup .k-window-content>.grid__cell .control-group__field--wide{flex:1 1 auto}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
