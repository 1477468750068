// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".active-icon-container{display:inline-flex;width:40px;height:40px;position:relative;margin-left:4px;margin-right:2px}.active-icons-container{position:sticky;top:0;padding:.5rem;z-index:2005;background-color:#f3f3f3;display:flex;justify-content:flex-end}.active-icons-container .position{position:absolute}.active-user-profile-picture{width:30px;height:30px}@keyframes slideInLeftAnimation{0%{transform:translateX(-35%);opacity:0}100%{transform:translateX(0);opacity:1}}.animated{animation:slideInLeftAnimation .65s ease-out}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
