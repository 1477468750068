// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".bizzstream-dialog{font-family:\"Ubuntu\",sans-serif;font-size:1.25em}.bizzstream-dialog .k-window{border-radius:5px !important;box-shadow:none;max-height:100%}.bizzstream-dialog .k-window-titlebar{border-top-left-radius:5px;border-top-right-radius:5px;background-color:#f2f3f4;color:#4d4d4d;height:40px;box-sizing:border-box;padding:0 8px;font-family:\"Ubuntu\",sans-serif}.bizzstream-dialog .k-window-titlebar .k-button-icon{font-size:10px}.bizzstream-dialog .k-window-titlebar .k-icon.k-i-window-minimize:before{position:relative;top:2px}.bizzstream-dialog .k-window-content{gap:16px;display:flex;flex-flow:column}.bizzstream-dialog .k-window-actions{padding-top:0;margin:0}.bizzstream-dialog .k-window-actions .k-button{flex:1;max-width:150px}.bizzstream-dialog.k-window-focused{border-radius:5px;box-shadow:none !important}.bizzstream-dialog .bizzstream-dialog-text{white-space:pre-line;margin:10px 5px}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
