// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".popup-layout{flex:1 1 auto;position:relative;display:flex;box-sizing:border-box;width:100%}.popup-layout__wrapper{position:relative;z-index:1;display:flex;align-items:center;justify-content:center;width:100%;margin:50px auto 50px auto;padding:0 20px}@media(min-width: 1280px){.popup-layout__wrapper{max-width:1200px}}@media(min-width: 769px){.popup-layout__wrapper{justify-content:initial}}@media(min-width: 425px){.popup-layout__wrapper{padding:0}}.popup-layout__content{display:flex;flex-flow:column;width:100%}@media(min-width: 1280px){.popup-layout__content{padding-left:70px}}@media(min-width: 769px){.popup-layout__content{padding-left:150px}}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
