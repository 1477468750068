// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".title-with-line{font-size:0.7rem;cursor:pointer;display:flex;align-items:center;gap:18px;height:40px;width:100%;color:var(--sidebar-text-color);text-transform:uppercase;user-select:none}.title-with-line__title{opacity:.8;display:inline-flex;width:100%}.title-with-line__line{background-color:#eeca2b;height:2px;width:28px}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
