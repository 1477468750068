// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".aside-menu{background-color:var(--sidebar-background-color);color:var(--sidebar-text-color);padding:0 0 20px 0;box-sizing:border-box;height:100%;width:100%;overflow-x:hidden;overflow-y:auto;isolation:isolate}.aside-menu__header{background-color:var(--sidebar-background-color);padding:20px 16px 8px 16px;display:flex;flex-flow:column;position:sticky;top:0;z-index:300}.aside-menu span.k-treeview-leaf{padding:0}.aside-menu span.k-treeview-leaf .k-icon{margin:0}.aside-menu span.k-treeview-leaf:hover{background-color:rgba(0,0,0,0);color:inherit}.aside-menu span.k-treeview-leaf.k-focus{box-shadow:none}.aside-menu span.k-treeview-leaf.k-selected{background-color:initial}.aside-menu .k-treeview-leaf-text,.aside-menu .k-treeview-leaf,.aside-menu .k-child-animation-container{width:100%}.aside-menu .k-animation-container{border-radius:0}.aside-menu .k-treeview-item{padding:0}.aside-menu .k-treeview-item .k-animation-container.k-animation-container-relative{border-radius:0}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
