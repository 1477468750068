// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".form-control{display:flex;flex-flow:column;align-items:flex-end;gap:14px}.form-control__label{font-size:0.875rem;color:rgba(71,75,97,.6);width:100%;margin:0}.form-control__input{font-size:0.875rem;height:52px;width:100%;border-radius:0;background-color:rgba(0,0,0,0);border-color:rgba(0,0,0,0);box-sizing:border-box;padding:0;color:#474b61;border-width:0 0 2px 0;border-bottom:2px solid #282634;outline:none}.form-control__input::placeholder{color:#d9d9d9}.form-control__input--error{border-bottom:2px solid var(--header-background-color)}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
