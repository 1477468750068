// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".control-group{display:flex;justify-content:flex-start;align-items:flex-start;position:relative;margin:10px;min-height:30px}.control-group:not(.control-group--stacked-label){flex-direction:row}.control-group>.control-group{margin:0}.control-group--no-margins{margin:0}.control-group--no-margins .control-group__field{margin-left:0;max-width:initial}.control-group--wide{max-width:initial;display:flex}.control-group--wide>div{width:100%}.control-group--wide .control-group__field{margin-left:0;max-width:initial}.control-group.control-group--field-group{margin:10px 0}.control-group--inline-label{align-content:center}.control-group--stacked-label{max-width:initial;flex-direction:column;align-items:stretch;display:flex;flex-wrap:nowrap;justify-content:flex-start}.control-group--stacked-label .label{flex:100%;width:100%;max-width:100%;padding-right:0}.control-group--stacked-label .label.label--required:after{padding-left:2px}.control-group--stacked-label__control{flex:100%;width:100%;flex-direction:row;display:flex;align-items:stretch;justify-content:space-between}.control-group--stacked-label__control .control-group__field{margin-left:0;max-width:initial;width:100%}.control-group--stacked-label__control .control-group__field--wide{flex:1}.control-group__field{height:fit-content;align-self:center;min-width:0}.control-group__field--wide{height:fit-content;align-self:center;display:flex;flex:1;max-width:initial;width:100%;flex-direction:column}.control-group__validation{display:inline-block;color:#e8751c;font-size:25px}.flex-wrap{flex-wrap:wrap}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
