// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".checkbox{font-size:0.71875rem;position:relative;user-select:none;cursor:pointer;color:rgba(38,40,52,.5);transition:color .1s;display:inline-flex;align-items:center}.checkbox:hover{color:rgba(38,40,52,.8)}.checkbox__input:checked~.checkbox__checkmark{font-size:10px}.checkbox__input{position:absolute;opacity:0;cursor:pointer;height:0;width:0}.checkbox__checkmark{height:12px;width:12px;border:1px solid rgba(38,40,52,.5);border-radius:2px;background-color:#fff;box-sizing:border-box;font-size:0}.checkbox__label{padding-left:8px}", ""]);
// Exports
exports.locals = {
	"inputControlMargin": "10px",
	"zIndexWindow": "10003"
};
module.exports = exports;
